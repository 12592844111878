<template>
  <div class="step-content py-4 text-left">
    <v-progress-linear
      v-if="step.evaluation.score==null"
      indeterminate
      color="secondary"
      class="mb-4"
    />
    <div 
      v-else
      class="score-display mb-4"
    >
      <div class="evaluation-bar d-flex v-progress-linear theme--dark">
        <div class="evaluation-bar-grade v-progress-linear__determinate red" />
        <div class="evaluation-bar-grade v-progress-linear__determinate orange" />
        <div class="evaluation-bar-grade v-progress-linear__determinate yellow" />
        <div class="evaluation-bar-grade v-progress-linear__determinate light-green" />
        <div class="evaluation-bar-grade v-progress-linear__determinate primary darken-1" />
      </div>
      <div 
        class="score-pointer elevation-12" 
        :class="evaluationColor"
        :style="{ 'left': (step.evaluation.score*100) + '%' }"
      />
    </div>

    <v-list 
      two-line
      color="transparent"
      class="feedbacks my-6"
    >
      <v-list-item
        v-for="item in step.evaluation.feedbacks"
        :key="item.criteria"
        class="px-0"
      >
        <v-list-item-icon class="icon">
          <v-icon color="amber">
            {{ icons.mdiAlertCircleOutline }}
          </v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title
            class="item-title"
          >
            {{ criteriaTitle(item.criteria) }}
          </v-list-item-title>
          <v-list-item-subtitle
            class="item-subtitle"
          >
            {{ item.desc }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <div
      class="instruction text-center my-12"
    >
      <img 
        src="@/assets/instruction-icon.svg"
        class="mb-4"
      >
      <h6 class="text-h6">
        O que acontece agora?
      </h6>
      <p class="text-body-1 mt-4 mb-3">
        É só aguardar!
      </p>
      <v-divider 
        class="mt-0 mx-auto" 
        style="max-width: 25vw;"
      />
      <p 
        v-html="openning.text" 
        class="text-body-2 mt-4 px-4" 
      />
    </div>

    <div
      class="steps-timeline text-center my-10"
    >
      <h6 class="text-h6 my-4">
        Etapas do Processo Seletivo
      </h6>
      <v-timeline
        align-top
        clipped
        dense
        class="text-left"
      >
        <v-timeline-item
          color="transparent"
          icon-color="primary"
          large
          class=""
        >
          <template v-slot:icon>
            <v-icon color="primary">{{ icons.mdiCardAccountDetailsOutline }}</v-icon>
          </template>
          <p class="text-subtitle-1 ml-2 mb-0">Cadastro</p>
          <p class="text-body-2 text--secondary ml-2 mb-0">Início do processo</p>
        </v-timeline-item>
        <v-timeline-item
          color="transparent"
          large
          class=""
        >
          <template v-slot:icon>
            <v-icon color="primary">{{ icons.mdiClipboardCheckOutline }}</v-icon>
          </template>
          <p class="text-subtitle-1 ml-2 mb-0">Avaliação do Perfil</p>
          <p class="text-body-2 text--secondary ml-2 mb-0">Envio de informações</p>
        </v-timeline-item>
        <v-timeline-item
          color="transparent"
          large
          class=""
        >
          <template v-slot:icon>
            <v-icon color="primary">{{ icons.mdiCarClock }}</v-icon>
          </template>
          <p class="text-subtitle-1 ml-2 mb-0">Aguardando Novas Vagas</p>
          <p class="text-body-2 primary--text font-weight-medium ml-2 mb-0">Você está aqui!</p>
        </v-timeline-item>
        <v-timeline-item
          color="transparent"
          large
          class=""
        >
          <template v-slot:icon>
            <v-icon color="grey">{{ icons.mdiFileSearchOutline }}</v-icon>
          </template>
          <p class="text-subtitle-1 ml-2 mb-0">Avaliação de Documentos</p>
          <p class="text-body-2 text--secondary ml-2 mb-0">Envio de documentação</p>
        </v-timeline-item>
        <v-timeline-item
          color="transparent"
          large
          class=""
        >
          <template v-slot:icon>
            <v-icon color="grey">{{ icons.mdiFilterOutline }}</v-icon>
          </template>
          <p class="text-subtitle-1 ml-2 mb-0">Seleção Mobees</p>
          <p class="text-body-2 text--secondary ml-2 mb-0">{{ openning.date }}</p>
        </v-timeline-item>
      </v-timeline>

      <p class="text-body-2 mt-8">
        Enquanto isso, se qualquer aspecto do seu perfil mudar, mantenha seu cadastro atualizado. 😉
      </p>
      <v-btn
        color="primary"
        text
        class="my-2"
        @click="toggleView('profile')"
      >
        <v-icon
          small
          class="mr-4 ml-n1"
        >
          {{ icons.mdiAccountCircle }}
        </v-icon>
        Atualizar perfil
      </v-btn>
    </div>
    

    <!-- <v-btn 
      text
      small
      class="help-btn mt-n2 mb-8"
      color="grey lighten-2"
      @click="toggleHelp(true, 'signup')"
    >
      <v-icon
        small
        class="mr-4 ml-n1"
      >
        {{ icons.mdiHelpCircleOutline }}
      </v-icon>
      Alguma dúvida?
    </v-btn> -->
      <!-- <v-alert 
        v-show="openning.toggle"
        color="primary"
        text
        transition="expand-transition"
        :icon="icons.mdiClockOutline"
        class="openning body-2 pa-4 mb-2"
      >
        <h5 class="subtitle-1 font-weight-medium">
          O que acontece agora?
        </h5>
        <span v-html="openning.text" /> <br>
        <span v-if="openning.date!='–'&&openning.date!=null">
          Previsão de abertura: <b>{{ openning.date }}</b>
        </span>
      </v-alert> -->

    <v-card 
      outlined
      color="transparent"
      class="faq mx-n4 my-8"
    >
      <v-list 
        flat
        color="transparent"
        class="py-0"
      >
        <v-list-item
          class="faq-item py-2"
        >
          <v-list-item-icon class="mr-4">
            <v-icon color="primary">{{ icons.mdiHelpCircleOutline }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="text-subtitle-1 font-weight-medium">
              Perguntas Frequentes
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-for="(faq, key) in faq"
          :key="key"
          class="faq-item py-1"
          @click="toggleHelp(true, 'signup', faq.section, faq.id)"
        >
          <v-list-item-title v-text="faq.title" />
        </v-list-item>
      </v-list>
      <v-divider class="mt-0" />
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="toggleHelp(true, 'signup')"
        >
          Ver mais
        </v-btn>
      </v-card-actions>
    </v-card>

    <slot name="quick-access" />

    <!-- <v-card 
      v-if="device.isAndroid&&!user.profile.gps"
      outlined
      color="transparent"
      class="alerts mx-n4"
    >
      <v-card-text>
        <div class="subtitle-1">
          Habilite o GPS
        </div>
        <div class="mt-2 body-2">
          Para garantir uma avaliação mais justa, permita o compartilhamento de seu GPS. Fique tranquilo, suas informações estão seguras conosco.
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="enableGeolocation"
        >
          Habilitar GPS
        </v-btn>
      </v-card-actions>
    </v-card> -->

      <!-- <div
        v-if="!user.profile.push"
        class="px-0"
      >
        <v-card-text>
          <div class="subtitle-1">
            Fique atento às notificações!
          </div>
          <div class="mt-2 body-2">
            Quando tivermos novidades vamos enviar notificação aqui no app Parceiros.
          </div>
        </v-card-text>
        <v-divider />
      </div>
      <div
        v-else
        class="px-0"
      >
        <v-card-text>
          <div class="subtitle-1">
            Ative as Notificações
          </div>
          <div class="mt-2 body-2">
            Queremos avisar quando tivermos notícias! Para isso habilite as notificações para nosso app.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="enablePush"
          >
            Ativar Notificações
          </v-btn>
        </v-card-actions>
        <v-divider />
      </div> -->

    <v-card 
      outlined
      color="transparent"
      class="alerts mx-n4 my-4"
    >
      <v-card-text>
        <div class="subtitle-1">
          Indique seus amigos!
        </div>
        <div class="mt-2 body-2">
          Você pode ganhar R$ 100 por indicação, <a @click="toggleHelp(true, 'signup', 'signup', 4)">conheça nosso modelo de indicação aqui</a>.
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          text
          @click="copyURL"
        >
          Copiar link de indicação
        </v-btn>
      </v-card-actions>
    </v-card>

    <div
      class="social text-center mt-8"
    >
      <!-- <h5 class="py-2 body-2 text--secondary">
        Siga nossas redes sociais
      </h5> -->
      <v-btn
        v-for="item in social"
        :key="item.title"
        class="social-link my-4"
        style="max-width: 50vw"
        text
        large
        block
        @click="openURL(item.url)"
      >
        <v-icon
          class="mr-4"
          color="secondary"
        >
          {{ icons[item.icon] }}
        </v-icon>
        {{ item.title }}
      </v-btn>
      <v-btn 
        text
        small
        class="help-btn mt-6"
        color="grey"
        @click="toggleHelp(true, 'signup')"
      >
        <v-icon
          small
          class="mr-4"
        >
          {{ icons.mdiHelpCircleOutline }}
        </v-icon>
        Precisa de ajuda?
      </v-btn>
    </div>
  </div>
</template>

<style>

  .score-display {
    position: relative;
  }
  .evaluation-bar {
    height: 16px;
    border-radius: 4px;
  }
  .evaluation-bar .evaluation-bar-grade {
    position: relative;
    left: auto;
    width: 20%;
    height: 16px;
    opacity: .75;
  }
  .score-display .score-pointer {
    position: absolute;
    top: -8px;
    width: 32px;
    height: 32px;
    transform: translateX(-50%);
    border-radius: 4px;
  }

  .step-content .icon {
    margin-right: 16px !important;
  }
  .step-content .item-title {
    margin: 6px 0;
  }

  .step-content .item-subtitle {
    line-height: 1.4;
    text-overflow: initial;
    white-space: initial;
  }

  .step-content .alerts, .step-content .faq {
    max-width: initial;
    border-color: var(--v-grey-darken3) !important;
  }

  .step-content .faq .faq-item:not(:last-child) {
    border-bottom: 1px solid var(--v-grey-darken3) !important;
  }

</style>

<script>

  // Icons
  import {
    mdiAlertCircleOutline,
    mdiBell,
    mdiMapMarker,
    mdiClockOutline,
    mdiClose,
    mdiInstagram,
    mdiWeb,
    mdiPostOutline,
    mdiHelpCircleOutline,
    mdiAccountCircle,
    mdiCardAccountDetailsOutline,
    mdiClipboardCheckOutline,
    mdiFileSearchOutline,
    mdiFilterOutline,
    mdiCarClock
  } from '@mdi/js'

  // Utilities
  import services from '@/services.js'
  import { sync, get } from 'vuex-pathify'
  import _ from 'lodash';


  export default {
    name: 'Step2',

    props: {
      step: {
        type: Object,
        default: () => {}
      },
      appURL: {
        type: String,
        default: ''
      },
      device: {
        type: Object,
        default: () => null
      }
    },

    components: {
      // CompletionProgress: () => import('@/components/CompletionProgress'),
    },

    data: () => ({
      api: process.env.VUE_APP_ROOT_API,
      icons: {
        mdiAlertCircleOutline,
        mdiBell,
        mdiMapMarker,
        mdiClockOutline,
        mdiClose,
        mdiInstagram,
        mdiWeb,
        mdiPostOutline,
        mdiHelpCircleOutline,
        mdiAccountCircle,
        mdiCardAccountDetailsOutline,
        mdiClipboardCheckOutline,
        mdiFileSearchOutline,
        mdiFilterOutline,
        mdiCarClock
      },
      openning: {
        toggle: false,
        text: '',
        date: '',
      },
      faq: [
        {
          title: 'Quando serei chamado?',
          section: 'signup',
          id: 1,
        },
        {
          title: 'Quais são os critérios de seleção para ser um Parceiro?',
          section: 'signup',
          id: 2,
        },
        {
          title: 'Eu posso indicar outros Motoristas?',
          section: 'signup',
          id: 4,
        },
        {
          title: 'Eu preciso ter rack para instalação da tela?',
          section: 'signup',
          id: 5,
        },
      ],
      criteria: {
        'dt_nasc_motorista': 'Idade',
        'cidade': 'Cidade',
        'marca_modelo': 'Modelo do Veículo',
        'ano_fab': 'Ano Fabricação',
        'dono': 'Dono',
        'estacionamento': 'Estacionamento',
        'mod_trab': 'Categoria',
        'nr_rodadas': 'Dedicação',
        'regiao_rodada': 'Região onde roda',
        'nota_app': 'Nota Aplicativo',
        'tempo_motorista': 'Experiência',
        'nivel_env': 'Motorista 100%',
      },
      social: [
        {
          title: 'Siga nosso Instagram!',
          icon: 'mdiInstagram',
          url: 'https://www.instagram.com/mobees_br/',
        },
        // {
        //   title: 'Site',
        //   icon: 'mdiWeb',
        //   url: 'https://mobees.com.br/',
        // },
        // {
        //   title: 'Blog',
        //   icon: 'mdiPostOutline',
        //   url: 'https://blog.mobees.com.br/',
        // },
      ],
      btnLocation: {
        toggle: true,
        loading: false,
      }
    }),

    computed: {
      profileView: sync('app/views@profile'),
      status: sync('user/status'),
      user: sync('user'),
      gps: sync('user/profile@gps'),
      toast: sync('app/toast'),
      help: sync('app/views@help'),
      help_sections: sync('help/sections'),

      evaluationColor () {
        const score = this.step.evaluation.score;
        let color = 'red';
        if (score>=.8) {
          color = 'primary darken-1';
        }else if (score>=.6) {
          color = 'light-green';
        }else if (score>=.4) {
          color = 'yellow';
        }else if (score>=.2) {
          color = 'orange';
        }
        return color;
      },

      referralURL () {
        return 'https://mobees.com.br/dirija?email-indicacao='+this.user.profile.email;
      },

      installed () {
        return typeof mobees != 'undefined';
      },

      stepName () {
        return this.$options.name.toLowerCase();
      }
    },

    methods: {
      ...services,

      actionCalled () {
        // no action
      },

      updateView () {

      },

      openURL (url) {
        window.open(url);
      },

      async enableGeolocation () {
        if (this.installed) {
          const cpf = this.rawCPF(this.user.cpf);
          const token = this.user.auth.token;
          mobees.requestGpsPermission((result) => {
            mobees.hasGpsPermission((result) => {
              this.gps = (result=='true');
              console.log('GPS: '+this.gps);
              
              this.$api.post('/setgeolocationpermission', {
                cpf: cpf,
                authTk: token,
                permitted: this.gps
              })
              .then(response => {
                console.log('setGeolocationPermission => ', response);
                if(response.data.retorno==200){
                  // saved
                  this.trackEvent('geolocation', this.gps ? 'enabled' : 'disabled');
                }else{
                  this.trackEvent('geolocation', 'set state error');
                }
              })
            }, () => {
              // TODO handle native access error
            });
          });
        }
      },

      criteriaTitle (v) {
        return this.criteria[v];
      },

      createKey (text) {
        const key = this.referralURL;
        return {
          text: typeof text != 'undefined' ? text.replace(/{{key}}/g, key) : '',
          key: key
        };
      },

      copyURL () {
        // this.copyStringToClipboard(this.createKey().key);
        if (this.installed) {
          cordova.plugins.clipboard.copy(this.referralURL);
          this.onCopyURL();
        }else{
          navigator.clipboard.writeText(this.referralURL).then(this.onCopyURL, function(err) {
            console.error('Async: Could not copy text: ', err);
          });
        }

        console.log(this.referralURL);
      },
        
      onCopyURL () {
        // this.dialog.buttons.ok.text = 'Copiado!'
        this.toggleToast(
          true,
          'Link copiado! Compartilhe o link nas suas redes sociais 😉',
          5000,
          false
        );
      },

      // copyStringToClipboard (str) {
      //   // Create new element
      //   var el = document.createElement('textarea');
      //   // Set value (string to be copied)
      //   el.value = str;
      //   // Set non-editable to avoid focus and move outside of view
      //   el.setAttribute('readonly', '');
      //   el.style = {position: 'absolute', left: '-9999px'};
      //   document.body.appendChild(el);
      //   // Select text inside element
      //   el.select();
      //   // Copy text to clipboard
      //   document.execCommand('copy');
      //   // Remove temporary element
      //   document.body.removeChild(el);
      // },

      getEvaluation () {
        const token = this.user.auth.token;
        const cpf = this.rawCPF(this.user.cpf);

        this.$api({
          url: '/getevaluationdriver',
            method: 'POST',
            data: {
              cpf: cpf,
              authTk: token,
            }
          })
          .then(response => {
            console.log('getEvaluationDriver => ',response);
            if(response.data.retorno==200){
              const status = _.has(response.data, 'status_motorista') ? response.data.status_motorista : 'FDB';
              if (this.status!=status) {
                this.status = status;
                this.$emit('next', { status });
              }else{
                this.step.evaluation.score = response.data.score;
                this.step.evaluation.feedbacks = response.data.feedbacks;
                if (this.step.evaluation.feedbacks.length==0) {
                  this.step.subtitle = 'Você tem potencial para ser um Parceiro Mobees. 🎉';
                }else{
                  this.step.subtitle = 'Seu perfil tem alguns pontos de atenção. Veja abaixo o que pode melhorar: ';
                }
              }

              this.trackEvent('signup/'+this.stepName, 'get evaluation ok');
            }else{
              this.toggleToast(
                true,
                'Não foi possível carregar sua avaliação.',
                5000,
                false,
              );
              this.trackEvent('signup/'+this.stepName, 'request evaluation failed');
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            
          });
      },

      getNextOpenning () {
        const token = this.user.auth.token;
        const cpf = this.rawCPF(this.user.cpf);

        this.$api({
          url: '/getnextopenningdriver',
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            data: {
              cpf: cpf,
              authTk: token,
            }
          })
          .then(response => {
            console.log('getNextOpenning => ',response);
            if(response.data.retorno==200){
              this.openning.toggle = true;
              this.openning.text = response.data.text;
              this.openning.date = response.data.date;

            }else{
              // this.toggleToast(
              //   true,
              //   'Não foi possível carregar sua avaliação.',
              //   5000,
              //   false,
              // );
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            
          });
      },

    },

    created () {
    },

    mounted () {
      this.getEvaluation();
      this.getNextOpenning();
    },

    directives: {
    },

  }
</script>
